import React, {useState, useEffect} from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import Select from '../../components/UI/Select';
import services from '../../services';
import Chevron from '../../assets/images/circle-arrow.png';
import Head from 'next/head';

const Blog = ({blogs, categories}) => {
    const [newBlogs, setNewBlogs] = useState(blogs);
    const [page, setPage] = useState(1);
    const [result, setResult] = useState([]);
    const router = useRouter();
    const category = router.query.category;
    const [sorting, setSorting] = useState(category !== undefined ? category : CategorySorting(categories)[0]);

    function categoryFilter (category = 1) {
        router.replace({
            query: { ...router.query, category: categories.find(c => c.name === category).name},
        });
        let selectedCategory = categories.find(c => c.name === category);
        RegisteredFunds (1);
        setSorting(category);
        if (selectedCategory.id !== 1) {
            setNewBlogs([...blogs].filter(blog => {
                if (blog.categories.find(c => c === selectedCategory.id)) return blog;
            }))
        } else {
            setNewBlogs(blogs);
        }
    }

    function RegisteredFunds (page = 1) {
        setPage(page);
        setResult([...newBlogs].slice((page - 1) * 8).slice(0, 8));
    }

    function CategorySorting (categories) {
        return categories.map(item => {return item?.name}).sort((a, b) => a.length - b.length);
    }

    function createPages(blogs) {
        const pages = Array.from({ length: Math.round(blogs.length / 8 + 1) }, (value, index) => index);
        const array = pages.splice(1, pages.length);
        if (array.length > 3) {
            return [...array].map(item =>
                page > array.length / 2
                    ? item !== array[0] ? item === page - 2 ? "..." : item : item
                    : item !== array.length ? item === page + 2 ? "..." : item : item
            ).filter(index => {
                    return !(index !== array[0] &&
                        index !== array.length &&
                        index !== page + 1 &&
                        index !== "..." && (page <= array.length / 2 ?
                            (index !== page + 1 && index > page) :
                            (index !== page - 1 && index < page))
                    )
                }
            )
        } else {
            return array;
        }
    }

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [page]);

    return (
        <>
            <Head>
                <title>Little Honey Money</title>
                <meta property="og:title" content="Little Honey Money" />
                <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}/journal`} />
                <meta property="og:image" content="/images/get-1.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Journal" />
                <meta property="og:locale" content="en_US" />
            </Head>
            <section className="blog__hero">
                <div className="blog__hero-container">
                    <div className="blog-container">
                        <div className="blog__hero-content">
                            <h1>{categories.find(a => {return a.name === sorting}).description}</h1>
                            <Select
                                empty={false}
                                value={sorting}
                                options={CategorySorting (categories)}
                                register={() => CategorySorting (categories)}
                                onChange={e =>categoryFilter(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog__posts">
                <div className="blog-container">
                    <div className="blog__posts-grid">
                        {result.map((blog, key) => {
                            return (
                                <div className="blog-post-card" key={`blog-${key}`}>
                                    <Link href={`journal/${blog.slug}`}>
                                        <a>
                                            <p>READ</p>
                                            <img
                                                src={blog._embedded['wp:featuredmedia'][0].source_url}
                                                width={586}
                                                height={419}
                                                alt="image"
                                            />
                                        </a>
                                    </Link>
                                    <div className="blog-post-info">
                                        <span>{categories.find(category => category.id === blog.categories[0]).name}</span>
                                        <div>
                                            <Link href={`journal/${blog.slug}`}><a dangerouslySetInnerHTML={{ __html: blog?.title?.rendered }}></a></Link>
                                        </div>
                                        <Link href={`journal/${blog.slug}`}><a className="round-btn">Read</a></Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="blog__posts-pagination">
                        {page !== createPages(newBlogs)[0] &&
                            <button
                                className="paginate-left"
                                onClick={() => RegisteredFunds(page - 1)}>
                                <Image src={Chevron}/>
                            </button>
                        }
                        {createPages(newBlogs).map((index, key) => {
                            return (
                                <button disabled={index === '...'}
                                        key={`page-${key}`}
                                        onClick={() => RegisteredFunds(index)}
                                        className={`blog--pagination ${index === page ? 'active' : ''}`}
                                >
                                    {index}
                                </button>
                            )
                        })}
                        {page !== createPages(newBlogs)[createPages(newBlogs).length - 1] &&
                            <button
                                className="paginate-right"
                                onClick={() => RegisteredFunds(page + 1)}>
                                <Image src={Chevron}/>
                            </button>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export const getStaticProps = async () => {
    const blogs = await services.api.journal.getAllBlogs();
    const categories = await services.api.journal.getAllCategories();

    return {
        props: {
            blogs,
            categories
        },
        revalidate: 60 * 2
    }
}

export default Blog;