import { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import Image from './Image'

function Select (props) {
    const {
        value,
        label,
        id,
        options,
        className,
        empty = true,
        register: {onChange, ...register},
        ...rest
    } = props
    const wrapperRef = useRef(null)
    const [focus, setFocus] = useState(false)
    const [stateValue, setStateValue] = useState(value)

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange({ target: { name: props.name, value: stateValue } })
        } else if (rest.onChange) {
            rest.onChange({ target: { name: props.name, value: stateValue } })
        }
    }, [stateValue])

    const getInput = () => {
        return wrapperRef.current ? wrapperRef.current.querySelector('select') : {}
    }

    const init = async () => {
        if (typeof document !== 'undefined' && window.innerWidth > 768) {
            await import('selectric')
            $(getInput()).selectric({
                arrowButtonMarkup: '',
                onChange (target) {
                    // onChange({ target })
                    setStateValue(target.value)
                    if (onChange) {
                        onChange({ target: { name: rest.name, value: target.value } })
                    } else if (rest.onChange) {
                        rest.onChange({ target: { name: rest.name, value: target.value } })
                    }
                },
                onOpen () {
                    setFocus(true)
                },
                onClose() {
                    setFocus(false)
                }
            })
        }
    }

    return <div className={`inp-primary ${stateValue || focus ? 'focus' : ''} ${className}`} ref={wrapperRef}>
        <select
            defaultValue={stateValue}
            onChange={e => setStateValue(e.target.value)}
            id={id}
            {...(register ? {} : { value: stateValue })}
            {...rest}
            {...register}
        >
            {empty && <option value=""/>}
            {options.map(item => {
                const { value, title } = item
                const isObj = typeof item === 'object'
                return <option key={isObj ? value : item} value={isObj ? value : item}>
                    {isObj ? title : item}
                </option>
            })}
        </select>
        <label htmlFor={id}>{label}</label>
        <Image width={15} height={15} placeholder={null} src="/images/signup-chevron.svg" className="select-chevron"/>
    </div>
}

export default Select
